import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import WorkList from '../../components/WorkList';

import { Wrapper, Section, Alert } from './style';

const CVTemplate = ({ data }) => {
  const {
    intro: {
      childMarkdownRemark: { html: introHTML },
    },
    cv: {
      childMarkdownRemark: { html: cvHTML },
    },
    biography: {
      childMarkdownRemark: { html: biographyHTML },
    },
    alerts,
    featuredWork,
    ongoingWork,
    pastWork,
  } = get(data, 'allContentfulSettings.edges[0].node');

  return (
    <Wrapper>
      <Header intro={introHTML} />
      <main>
        <Section
          dangerouslySetInnerHTML={{
            __html: biographyHTML,
          }}
        />
        {alerts
          ? alerts.map((alert) => (
              <Alert key={alert.id}>
                {alert.url ? (
                  <a href={alert.url} target="_blank" rel="noreferrer noopener">
                    {alert.text}
                  </a>
                ) : (
                  alert.text
                )}
              </Alert>
            ))
          : null}
        {featuredWork && featuredWork.length > 0 ? (
          <WorkList work={featuredWork} label="Featured Works" />
        ) : null}
        {ongoingWork && ongoingWork.length > 0 ? (
          <WorkList work={ongoingWork} label="Current & Ongoing Works" />
        ) : null}
        {pastWork && pastWork.length > 0 ? (
          <WorkList work={pastWork} label="Past Works" />
        ) : null}
        <Section dangerouslySetInnerHTML={{ __html: cvHTML }} />
      </main>
      <Footer />
    </Wrapper>
  );
};

export default CVTemplate;

export const homeQuery = graphql`
  query HomeQuery {
    allContentfulSettings(
      filter: { siteName: { ne: "TEMPLATE (DO NOT DELETE)" } }
      limit: 1
    ) {
      edges {
        node {
          siteName
          intro {
            childMarkdownRemark {
              html
            }
          }
          biography {
            childMarkdownRemark {
              html
            }
          }
          alerts {
            id
            text
            url
          }
          cv {
            childMarkdownRemark {
              html
            }
          }
          featuredWork {
            id
            title
            slug
            year
            ongoing
            collaborators
            thumbnail {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          ongoingWork {
            id
            title
            slug
            year
            ongoing
            collaborators
            thumbnail {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          pastWork {
            id
            title
            slug
            year
            ongoing
            collaborators
            thumbnail {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
