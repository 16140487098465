import styled from 'styled-components';

export const Wrapper = styled.header`
  position: absolute;
  bottom: 5vw;
  left: 5vw;
  width: 20vw;
  z-index: 1;

  @media screen and (max-width: 900px) {
    bottom: auto;
    left: auto;
    margin-top: 5.6rem;
    position: relative;
    width: auto;
  }

  a {
    background: none;
  }
`;
