import React, { useState, useEffect } from 'react';
import GatsbyImage from 'gatsby-image';

import useMousePosition from '../../hooks/useMousePosition';

import { Wrapper, ThumbnailWrapper } from './style';

const FeaturedWorkThumbnail = ({ work, activeId }) => {
  const { x, y } = useMousePosition();

  return (
    <Wrapper style={{ left: x, top: y }}>
      {work.map(({ id, thumbnail }, index) => (
        <ThumbnailWrapper isActive={activeId === id} key={id}>
          <GatsbyImage
            fadeIn={false}
            fluid={thumbnail.fluid}
            style={{
              bottom: 0,
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'top left',
            }}
          />
        </ThumbnailWrapper>
      ))}
    </Wrapper>
  );
};

export default FeaturedWorkThumbnail;
