import styled from 'styled-components';

export const Article = styled.article`
  margin-bottom: 2.8rem;
`;

export const Year = styled.div`
  margin: 1.4rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const List = styled.ul`
  font-size: 2rem;
  line-height: 2.8rem;
`;

export const Item = styled.li``;

export const Collaborators = styled.span`
  font-size: 1rem;
  padding-left: 1.4rem;
`;
