import styled from 'styled-components';

export const Wrapper = styled.article``;

export const Section = styled.section`
  margin: 2.8rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Alert = styled.aside`
  margin: -1.4rem 0 1.4rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 2.8rem;
  }
`;
