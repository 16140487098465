import styled from 'styled-components';

export const Wrapper = styled.div`
  pointer-events: none;
  position: fixed;
`;

export const ThumbnailWrapper = styled.div`
  height: 150px;
  left: 15px;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  position: absolute;
  top: 15px;
  visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
  width: 150px;
`;
