import React, { useState } from 'react';
import { Link } from 'gatsby';

import { Article, List, Item, Collaborators } from './style';
import WorkListThumbnail from '../WorkListThumbnail';

export default ({ work, label }) => {
  const [activeThumbnailId, setActiveThumbnailId] = useState();

  return (
    <Article>
      <h2>{label}</h2>
      <List>
        {work.map(({ id, title, slug, collaborators }) => (
          <Item key={id}>
            <Link
              to={`/work/${slug}`}
              onMouseMove={() => {
                setActiveThumbnailId(id);
              }}
              onMouseLeave={() => {
                setActiveThumbnailId();
              }}
            >
              {title}
              {collaborators ? (
                <Collaborators>[{collaborators}]</Collaborators>
              ) : null}
            </Link>
          </Item>
        ))}
      </List>
      <WorkListThumbnail work={work} activeId={activeThumbnailId} />
    </Article>
  );
};
