import React from 'react';

import { Wrapper } from './style';

const Footer = () => {
  return (
    <Wrapper>
      <p>
        Website by{' '}
        <a
          href="http://nate.van-der-en.de"
          target="_blank"
          rel="noreferrer noopener"
        >
          Nate&nbsp;van&nbsp;der&nbsp;Ende
        </a>
      </p>
    </Wrapper>
  );
};

export default Footer;
