import { useState, useEffect, useCallback } from 'react';

export default () => {
  const [mousePosition, setMousePosition] = useState({
    x: '100%',
    y: '100%',
  });

  const updateMousePosition = (evt) => {
    if (!evt) return;
    setMousePosition({
      x: evt.clientX,
      y: evt.clientY,
    });
  };

  useEffect(() => {
    updateMousePosition();
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  return mousePosition;
};
